import React, {useRef} from "react";
import {useTranslation} from "react-i18next";
import OwlCarousel from "react-owl-carousel";
import LoginSignupModal from "./LoginSignup/LoginSignupModal";
import {useNavigate, Link} from "react-router-dom";

const EmergencyCard = () => {
  const {t} = useTranslation();
  const loginSignupRef = useRef();
  let navigate = useNavigate();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const handelBook = (step) => {
    navigate(`/frequency/checkout/details?step=${step}`, {
      state: {
        serviceId: "62385633517c11eaf7568824",
        isBookNow: true,
        isLogin: currentUser ? true : false,
        ui_type: "Frequency"
      }
    });
  };

  const options = {
    margin: 20,
    responsiveClass: true,
    nav: false,

    dots: false,
    autoplay: true,
    rtl: true,
    ltr: false,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,

    navText: [
      "<button type='button' class='slick-prev'><i class='fa fa-angle-left' aria-hidden='true'></i></button>",
      "<button type='button' class='slick-next'><i class='fa fa-angle-right' aria-hidden='true'></i></button>"
    ],
    nxet: "",
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
        margin: 0
      },
      400: {
        items: 1
      },
      600: {
        items: 1
      },

      700: {
        items: 1
      },

      1000: {
        items: 2
      },
      1200: {
        items: 2
      }
    }
  };
  const getLearnMore = () => {
    if (currentUser) {
      navigate("/my-account/righthandcredit");
    } else {
      loginSignupRef.current.handleLoginSignupModalShow();
    }
  };
  return (
    <>
      <LoginSignupModal ref={loginSignupRef} />
      <div className="container homeCards pt-7">
        {/* <div className="applyCoupon">
        <h4>Applied Voucher Code</h4> 
        <h3>Summers <span>QAR 7.00 OFF</span></h3>
        </div> */}
        {/* <div className="row pt-7 d-flex englishcard">
            <div className="col-lg-6 col-sm-6 mb-2">
              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/extraup.png.webp" className="img-fluid" />
            </div>
            <div className="col-lg-6 col-sm-6">
              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/emergency2.png.webp" className="img-fluid" />
            </div>
          </div> */}

        {/* <div className="row pt-7 d-arabic d-sm-none">
            <div className="col-lg-6 col-sm-6 mb-2">
              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/extraup-arabic.png.webp" className="img-fluid" />
            </div>
            <div className="col-lg-6 col-sm-6">
              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/emergency-arabic.png.webp" className="img-fluid" />
            </div>
          </div> */}

        <div className="heading">
          <h2>{t("over_50_services_for_your_home")}</h2>
        </div>

        <div className="row  d-sm-flex">
          <OwlCarousel
            className="owl-theme"
            items={6}
            loop={false}
            touchDrag={false}
            mouseDrag={false}
            margin={25}
            nav
            {...options}
          >
            <div className="item">
              <div className="bg-primary extraCard emergencyCard cardGuarantee ">
                <div className="d-flex justify-content-between">
                  <div>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/happyImg2.webp" />
                  </div>
                  <div className="extraContent">
                    <h3>{t("will_takes_you_happiness_guarantee_age")}</h3>
                    <p>{t("fast_response")}</p>
                    {/* <button className="btn btn-secondary pull-right" onClick={()=>getLearnMore()}>
                      { t('learn_more') }
                    </button> */}
                    {currentUser ? (
                      <button
                        onClick={() => handelBook(1)}
                        className="btn btn-secondary pull-right"
                      >
                        {t("book_now_btn")}
                      </button>
                    ) : (
                      <button
                        onClick={() => handelBook(2)}
                        className="btn btn-secondary pull-right"
                      >
                        {t("book_now_btn")}
                      </button>
                    )}
                    {/* <button className="btn btn-secondary pull-right"onClick={(e) => document.getElementById('serviceShow').scrollIntoView()}>
                      { t('book_now_btn') }
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="item">
              <div className="bg-primary extraCard  offerCard">
                <div className="d-flex justify-content-between">
                  <div>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/happyImg3.webp" />
                  </div>
                  <div className="extraContent">
                    <h3>
                      {t("instant_booking_services_for_new_members_only")}
                    </h3>
                    <p></p>
                    {/* <button className="btn btn-secondary pull-right" onClick={()=>getLearnMore()} >
                      { t('learn_more') }
                    </button> */}
                    {currentUser ? (
                      <button
                        onClick={() => handelBook(1)}
                        className="btn btn-secondary pull-right"
                      >
                        {t("Check_Prices_availability")}
                      </button>
                    ) : (
                      <button
                        onClick={() => handelBook(2)}
                        className="btn btn-secondary pull-right"
                      >
                        {t("Check_Prices_availability")}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* <div className='item'>
              <div className="bg-primary extraCard">
                <div className="d-flex justify-content-between">
                  <div>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/phone.png.webp" />
                  </div>
                  <div className="extraContent">
                    <h3>{ t('extra_up_to') }Book with happiness guarantee</h3>
                    <p>
                      { t('priority_booking') }
                    </p>
                    <button className="btn btn-secondary pull-right">
                      { t('learn_more') }
                    </button>
                  </div>
                </div>
              </div>
              </div> */}

            {/* <div className='item'>
              <div className="bg-primary extraCard emergencyCard">
                <div className="d-flex justify-content-between">
                  <div>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/emergency.png.webp" />
                  </div>
                  <div className="extraContent">
                    <h3>
                      { t('24_7') }
                    </h3>
                    <p>{ t('fast_response') }</p>
                    <button className="btn btn-secondary pull-right">
                      { t('learn_more') }
                    </button>
                  </div>
                </div>
              </div>
              </div> */}
            {/* <div className='item'>
              <div className="bg-primary extraCard emergencyCard cardGuarantee ">
                <div className="d-flex justify-content-between">
                  <div>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/happyImg.png.webp" />
                  </div>
                  <div className="extraContent">
                    <h3>
                    { t('will_takes_you_happiness_guarantee_age') }
                    </h3>
                    <p>{ t('fast_response') }</p>
                    <button className="btn btn-secondary pull-right">
                      { t('learn_more') }
                    </button>
                  </div>
                </div>
              </div>
              </div> */}
            {/* <div className='item'>
              <div className="bg-primary extraCard clubCard">
                <div className="d-flex justify-content-between">
                  <div>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/phone2.png.webp" />
                  </div>
                  <div className="extraContent">
                    <h3>
                    { t('will_takes_you_rh_club') }
                    </h3>
                    <p>{ t('fast_response') }</p>
                    <button className="btn btn-secondary pull-right">
                      { t('learn_more') }
                    </button>
                  </div>
                </div>
              </div>
              </div> */}

            {/* <div className='item'>
              <div className="bg-primary extraCard  offerCard">
                <div className="d-flex justify-content-between">
                  <div>
                    <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/phone3.png.webp"  />
                  </div>
                  <div className="extraContent">
                    <h3>
                    { t('instant_booking_services_for_new_members_only') }
                    </h3>
                   
                    <button className="btn btn-secondary pull-right">
                      { t('learn_more') }
                    </button>
                  </div>
                </div>
              </div>
              </div> */}
          </OwlCarousel>
        </div>
      </div>
    </>
  );
};

export default EmergencyCard;
