import React, {useState, useEffect} from "react";
import i18next from "i18next";
import {useTranslation} from "react-i18next";
// import Loader from "./Loader";
import {NavLink, Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {ModifiedToastContainer} from "./ModifiedToastContainer";
// import CountryModal from './CountryModal'
import Modal from "react-bootstrap/Modal";
import jQuery from "jquery";
import {updateLanguageAction} from "../actions/updateLanguageAction";
import {countryListAction} from "../actions/userActions";
import ReactCountryFlag from "react-country-flag";
import {updateCountryAction} from "../actions/updateCountryAction";

const NavUser = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const currentLanguageCode = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";

  const getLanguageState = useSelector((state) => state.updateLanguageReducer);
  // const {loading} = getLanguageState;
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  // const [loading, setLoading] = useState(false);
  let selectedCountryId = "";
  // let countryName = '';
  // let countryList = {}
  // async function appointmentPending(){
  //     await pendingAppointment()
  //       .then((res) => {
  //         handlePendingAppointmentModalShow()
  //         console.log(res, '66666');
  //       }).catch((err) => {
  //         console.log("error: ", err);
  //       })
  //   }
  //     useEffect(()=>{
  //       if(currentUser) {
  //       appointmentPending();
  //       }
  //     },[])

  async function updateLanguage() {
    let listUrl = baseUrl + "/customer/update/language";
    var selectedLanguage = "";
    if (currentLanguage && currentLanguage === "ar") {
      selectedLanguage = "en";
    } else {
      selectedLanguage = "ar";
    }
    dispatch(
      updateLanguageAction(
        listUrl,
        {
          current_language: selectedLanguage
        },
        changeLanguage
      )
    );
  }

  async function changeLanguage(current_language) {
    localStorage.setItem("currentLanguage", current_language);
    i18next.changeLanguage(current_language);
    window.location.reload();
  }

  const logout = (e) => {
    toast.success(t("logout_successfully"), {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    setTimeout(() => {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("currentToken");
      localStorage.removeItem("currentLatitude");
      localStorage.removeItem("currentLongitude");
      window.location.href = "/";
    }, 1000);
  };

  const [showCountryModal, setShowCountryModal] = useState(false);
  const handleCountryModalShow = () => setShowCountryModal(true);
  const handleCountryModalClose = () => setShowCountryModal(false);

  const getAllCountryState = useSelector((state) => state.countryReducer);
  const {country_list} = getAllCountryState;
  const getUpdateData = useSelector((state) => state.updateCountryReducer);

  let listUrl = baseUrl + "/country/list";
  let countryUpdateUrl = baseUrl + "/customer/update/country";

  useEffect(() => {
    dispatch(countryListAction(listUrl, {}));
  }, [listUrl, dispatch]);

  const countryInformation = () => {
    handleCountryModalShow();
  };

  if (
    localStorage.getItem("userCountryId") &&
    localStorage.getItem("userCountryCode")
  ) {
    selectedCountryId = localStorage.getItem("userCountryId");
  } else {
    if (currentUser) {
    }
    // selectedCountryId = "638452497da67f7c6bf4fdcd";
    // localStorage.setItem('userCountryId',selectedCountryId);
    // localStorage.setItem('userCountryCode',"AE");
    // console.log(countryData)
    // selectedCountryId = countryData._id;
    // localStorage.setItem('userCountryId',selectedCountryId);
    //  localStorage.setItem('userCountryCode',countryData.country_flag);
  }

  async function updateCountry(e) {
    const country_and_flag = jQuery("input[name=country_id]:checked").val();
    const country_id = country_and_flag.substring(
      0,
      country_and_flag.indexOf(",")
    );
    const country_code = country_and_flag.split(",")[1].trim();
    // console.log("country_id", country_id);
    dispatch(updateCountryAction(countryUpdateUrl, country_id));

    setTimeout(() => {
      localStorage.removeItem("userCountryId");
      localStorage.removeItem("userCountryCode");
      localStorage.setItem("userCountryId", country_id);
      localStorage.setItem("userCountryCode", country_code);
      localStorage.setItem("currentCountryCode", country_code);
      window.location.reload();
    }, 500);

    if (country_list?.length > 0) {
      let country_obj = country_list.find(
        (o) => o.country_flag === localStorage.getItem("currentCountryCode")
      );
      if (country_obj && country_obj?.isActive) {
        localStorage.setItem("userCountryId", country_obj?._id);
        localStorage.setItem("userCountryCode", country_obj?.country_flag);
        localStorage.setItem("currentCountryCode", country_obj?.country_flag);
      }
    }
  }

  // useEffect(() => {
  //   setTimeout(() => {
  //     if(country_list?.length > 0){
  //       let country_obj = country_list.find(o => o.country_flag === localStorage.getItem('currentCountryCode'));
  //       if(country_obj && country_obj?.isActive){
  //         localStorage.setItem('userCountryId',country_obj._id);
  //         localStorage.setItem('userCountryCode',country_obj.country_flag);
  //         localStorage.setItem('currentCountryCode',country_obj.country_flag);
  //       }
  //     }
  //   }, 4500);
  // }, [country_list]);

  return (
    <>
      {/* {loading ? <Loader/> : ""} */}
      <nav
        className="navbar navbar-expand-lg navbar-light bg-light py-2"
        id="navFixed"
      >
        <div className="container">
          <Link
            to="/"
            className="navbar-brand p-0 d-none d-md-block"
            onClick={() => window.scrollTo(0, 0)}
          >
            <img alt="" src="/images/logo.webp" />
          </Link>
          {/* <Link to="/" className="px-3 d-none d-md-block" onClick={() => window.scrollTo(0, 0)}>
              Help
            </Link>  */}

          <div className="d-flex ms-auto align-items-center position-relative">
            <div
              className="loginbtn me-3 dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {currentUser && currentUser.name ? currentUser.name : "User"}{" "}
              <img
                src="https://dvdljkkxpxqo3.cloudfront.net/images/arrow-right2.webp"
                alt=""
              />
            </div>
            <ul className="dropdown-menu z-index-5">
              <li>
                <NavLink
                  to="/my-account/account-details"
                  className="dropdown-item"
                >
                  {t("account_details")}
                  <img
                    src="https://dvdljkkxpxqo3.cloudfront.net/images/profile.webp"
                    alt=""
                  />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/my-account/appointments"
                  className="dropdown-item"
                >
                  {t("appointments")}
                  <img
                    src="https://dvdljkkxpxqo3.cloudfront.net/images/appointments.webp"
                    alt=""
                  />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/my-account/righthandcredit"
                  className="dropdown-item"
                >
                  {t("rh_credits")}
                  <img
                    src="https://dvdljkkxpxqo3.cloudfront.net/images/rh-credit2.webp"
                    alt=""
                  />
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/my-account/righthandwallet"
                  className="dropdown-item"
                >
                  {t("rh_wallet")}
                  <img
                    src="https://dvdljkkxpxqo3.cloudfront.net/images/rh-credit2.webp"
                    alt=""
                  />
                </NavLink>
              </li>
              <li>
                <NavLink to="/membership" className="dropdown-item">
                  {t("rh_club")}
                  <img
                    src="https://dvdljkkxpxqo3.cloudfront.net/images/customer/rhclub.png.webp"
                    alt=""
                  />
                </NavLink>
              </li>
              <li>
                <NavLink to="/my-account/my-fav-pros" className="dropdown-item">
                  {t("my_favourite_pros")}
                  <img
                    src="https://dvdljkkxpxqo3.cloudfront.net/images/myfav.webp"
                    alt=""
                  />
                </NavLink>
              </li>

              {/* <li>
                <NavLink
                  to="/my-account/settings"
                  className="dropdown-item"
                >
                  {t('settings')}
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/settings.png.webp" />
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink
                  to="/my-account/appointments"
                  className="dropdown-item"
                >
                  {t('appointments')}
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/appointments.png.webp" />
                </NavLink>
              </li>              */}
              {/* <li>
                <NavLink
                  to="/my-account/free/credit"
                  className="dropdown-item"
                >
                  {t('free_credit')}
                  <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/Free-Credit.png.webp" />
                </NavLink>
              </li> */}
              {/* <li>
                <a
                  href="https://righthandssupport.zendesk.com/hc/en-us" 
                  target="_blank"
                  className="dropdown-item"
                >
                {t('help')} <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/help.png.webp" />
                </a>
              </li> */}

              <li>
                <NavLink
                  to="/my-account/free-service"
                  className="dropdown-item"
                >
                  {t("invite_and_earn")}
                  <img
                    src="https://dvdljkkxpxqo3.cloudfront.net/images/invite-earn.webp"
                    alt=""
                  />
                </NavLink>
              </li>
              <li
                onClick={() =>
                  (window.location.href =
                    "https://righthandssupport.zendesk.com/hc/en-us")
                }
              >
                <div
                  // to=""
                  className="dropdown-item"
                >
                  {t("get_support")}
                  <img
                    src="https://dvdljkkxpxqo3.cloudfront.net/images/get-support.webp"
                    alt=""
                  />
                </div>
              </li>

              <li>
                <NavLink to="/my-account/settings" className="dropdown-item">
                  {t("settings")}
                  <img
                    src="https://dvdljkkxpxqo3.cloudfront.net/images/settings.webp"
                    alt=""
                  />
                </NavLink>
              </li>

              {/* <NavLink
                  to="#"
                  className="dropdown-item"
                  onClick={logout}
                >{t('logout')} <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/logout.png.webp" />
                </NavLink> */}
            </ul>
            <a className="navbar-brand p-0 d-block w-75 d-md-none" href={"/#"}>
              <img
                src="https://dvdljkkxpxqo3.cloudfront.net/images/logo.webp"
                className="w-100"
                alt=""
              />
            </a>
            <div className="roundedCircle d-flex align-items-center px-1">
              <ReactCountryFlag
                svg
                style={{fontSize: "1.5em", lineHeight: "1.5em"}}
                countryCode={localStorage.getItem("userCountryCode")}
                onClick={countryInformation}
              />
              <div className="language ms-3">
                <div className="selectedLang" onClick={updateLanguage}>
                  {currentLanguage && currentLanguage === "ar"
                    ? "English"
                    : "عربي"}
                </div>
              </div>
              {/* <Link className="p-0 d-block bellIcon pl-3" to="/announcement">
                   <span className="fa fa-bell"></span>
              </Link> */}
            </div>
          </div>
        </div>
      </nav>
      <ModifiedToastContainer />

      <Modal
        show={showCountryModal}
        onHide={handleCountryModalClose}
        className="changeCountry"
        id="changeCountry"
      >
        <Modal.Header closeButton className="modal-header">
          <h3 className="modal-title">{t("select_your_country")}</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body">
            {country_list?.length > 0
              ? country_list.map((country, index) => {
                  return (
                    <div key={index}>
                      {country?.isActive && (
                        <label className="w-full languageChecked">
                          <p>
                            {currentLanguageCode === "en"
                              ? country?.name
                              : country?.name_arabic}
                          </p>
                          <input
                            type="radio"
                            value={country?.id + "," + country?.country_flag}
                            name="country_id"
                            className="language"
                            defaultChecked={country?.id === selectedCountryId}
                          />
                          <i className="fa fa-check" />
                        </label>
                      )}
                    </div>
                  );
                })
              : ""}
            <button
              type="button"
              className="btn btn-primary w-full mt-2"
              onClick={(e) => updateCountry(e)}
            >
              {t("ok")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {/* <CountryModal/> */}
      {/* AppointmentPending Model */}
    </>
  );
};

export default NavUser;
