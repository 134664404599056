import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import LoginSignupModal from "../../../components/LoginSignupModal";
import ServiceDetails from "./service_details/ServiceDetails";
import DateTime from "./date_time/DateTime";
import Payment from "./payment/Payment";
import Address from "./address/Address";
import Frequency from "./frequency/Frequency";
// import Modal from "react-bootstrap/Modal";
import NextBackButton from "./components/NextBackButton";
import Steps from "./components/Steps";
// import BookingLocationMap from "../service/components/BookingLocationMap";
import jQuery from "jquery";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import {ModifiedToastContainer} from "../../../components/ModifiedToastContainer";
import {saveForm} from "../../../helpers/helpers";
import {getAppointmentPriceAction} from "../../../actions/appointmentAction";
//import { freeCreditDiscountApplyAction } from "../../../actions/freeCreditDiscountApplyAction";
import Loader from "../../../components/Loader";
// import {postBookAppointmentAction} from "../../../actions/appointmentAction";
import SuccessBooking from "../../appointment_booking/service/components/SuccessBooking";
import {useCountryCurrency} from "../../../helpers/useCountryCurrency";
import {useLocation} from "react-router-dom";
// import LoginModal2 from "../../../components/LoginModal2";
import SuccessFullPayment from "./components/SuccessFullPayment";
import LoginSignupStep from "./service_details/LoginSingupStep";
const moment = require("moment");

const FrequencyCheckout = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const {dataObj = {}} = useSelector((state) => state.saveAddressReducer);
  const countryCurrency = useCountryCurrency();
  const [loginModalShow, setLoginModalShow] = useState(true);
  const [isLocation, setIsLocation] = useState(false);
  const loginSignupRef = useRef();
  const getAppointmentPriceState = useSelector(
    (state) => state.appointmentReducer
  );

  // const searchText = state;
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [packageType, setPackageType] = useState("");
  const [isAddressAdded, setIsAddressAdded] = useState(false);
  const getServiceInfoState = useSelector(
    (state) => state.serviceDetailsReducer
  );

  const [serviceInfo, setServiceInfo] = useState();
  const [pendingStatus, setPendingStatus] = useState(0);
  const [bookingData, setBookingData] = useState();
  
  // const getCustomerState = useSelector((state) => state.customerReducer);
  const {loading} = getAppointmentPriceState;
  const [checkoutData, setCheckoutData] = useState({
    appointment_id: localStorage.getItem("bookAppointmentId")
      ? localStorage.getItem("bookAppointmentId")
      : "",
    page: 1,
    is_home_cleaning: true,
    frequency_id: "",
    frequency_name: "",
    frequency_nameArabic: "",
    hours_count: 4,
    professionals_count: 1,
    is_cleaning_materials: false,
    booking_instruction: "",
    appointment_date: moment().format("YYYY-MM-DD"),
    appointment_date_start: "",
    start_time_between: "",
    is_auto_assign: true,
    cleaner_id: [],
    professional_id: "",
    professional_full_name: "Auto assign",
    professional_profile_image: "",
    qnb_customer_id: "",
    source_id: "",
    country_id: localStorage.getItem("userCountryId"),
    payment_mode: "",
    discount_type: "",
    promo_code: "",
    price: 0,
    holidayPrice: 0,
    rushSlot: 0,
    totalPrice: 280,
    vatPrice: 0,
    discountPrice: 0,
    frequencyDiscountPrice: 0,
    address_id: "",
    address: "",
    country: "",
    currency:
      countryCurrency?.length > 0
        ? countryCurrency[0]?.currency
        : localStorage.getItem("currency"),
    voucher: "",
    latitude: dataObj.lat ? dataObj.lat : 0.0,
    longitude: dataObj.long ? dataObj.long : 0.0,
    countryListData: [],
    pay_type: "",
    rh_discount: 0,
    rhClub: localStorage.getItem("rhClub"),
    customer_service_id: "",
    payment_terms_condition: false,
    isWallet: false,
    address_nickname: "",
    area: "",
    address_type: "",
    street: "",
    building: "",
    floor: "",
    house_no: "",
    Office_no: "",
    apartment_no: "",
    wallet_amount: "",
    frequency_no: "",
    frequnecy_status: false,
    actionStatus: false,
    priceAmount: 0,
    walletCash: false,
    total_amount: 0,
    total_discount: 0,
    total_frequency_amount: 0,
    total_per_display_amount: 0,
    total_per_paid_amount: 0,
    wayOfBooking: "",
    isBookNow: false,
    service_type: ""
  });
 
  // const [paymentTerms, setPaymentTerms] = useState(false);
  useEffect(() => {
   localStorage.getItem("currentToken") &&
    localStorage.getItem("currentToken") !== "null" &&
    localStorage.getItem("currentToken") !== ""
      ? setIsLoggedIn(true) 
      : setIsLoggedIn(false);
  });

  let currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;

  let frequencyCheckoutData =
    localStorage.getItem("frequencyCheckoutData") &&
    localStorage.getItem("frequencyCheckoutData") !== "null"
      ? JSON.parse(localStorage.getItem("frequencyCheckoutData"))
      : false;

  let frequencyAppointmentData =
    localStorage.getItem("frequencyAppointmentData") &&
    localStorage.getItem("frequencyAppointmentData") !== "null"
      ? JSON.parse(localStorage.getItem("frequencyAppointmentData"))
      : false;

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  const handleLoginModalHide = () => setLoginModalShow(false);
  // const {state} = useLocation();
  useEffect(() => {
    if (
      checkoutData.page === 5 &&
      checkoutData?.wayOfBooking === "withoutLogin" &&
      !isLoggedIn
    ) {
      setLoginModalShow(true);
    } else {
      handleLoginModalHide();
    }
  }, [checkoutData.page, checkoutData?.wayOfBooking]);
  const [showAddresModal, setShowAddressModal] = useState(false);
  const handleAddressModalShow = () => setShowAddressModal(true);
  const handleAddressModalClose = () => setShowAddressModal(false);
  const [bookingSuccessModal, setBookingSuccessModal] = useState(false);
  const handleBookingSuccessModalShow = () => setBookingSuccessModal(true);
  const [appointment, setAppointment] = useState("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const windowUrl = window.location.search;
  const params = new URLSearchParams(windowUrl);
  useEffect(() => {
    setCheckoutData({
      ...checkoutData,
      latitude: dataObj?.lat,
      longitude: dataObj?.long
    });
  }, [dataObj.lat, dataObj.long]);
  const {state} = useLocation();
  useEffect(() => {
    if(state?.isLogin === true  && state?.isBookNow === true){
      localStorage.getItem("currentToken") &&
      localStorage.getItem("currentToken") !== "null" &&
      localStorage.getItem("currentToken") !== ""
        ? setIsLoggedIn(true)
        : setIsLoggedIn(false);
        setCheckoutData((prevState) => ({
          ...prevState,
          wayOfBooking: ""
        }));
    }
    if (state?.isBookNow === true) {
      setPackageType(state?.ui_type);
      localStorage.setItem("serviceId", state?.serviceId);
      setCheckoutData((prevState) => ({
        ...prevState,
        service_id: state?.serviceId,
        isBookNow: true,
        ui_type: state?.ui_type,
        country_id: localStorage.getItem("userCountryId")
      }));
    } else if (state && state?.appointment_process && pendingStatus === 0) {
      setPackageType(state?.ui_type);
      setCheckoutData((prevState) => ({
        ...prevState,
        ui_type: state?.ui_type,
        appointment_id: state?.appointment_process._id
          ? state?.appointment_process?._id
          : "",
        is_home_cleaning: state?.appointment_process?.is_home_cleaning
          ? state?.appointment_process?.is_home_cleaning
          : true,
        frequency_id: state?.appointment_process?.frequency_id
          ? state?.appointment_process?.frequency_id
          : "",
        hours_count: state?.appointment_process?.hours_count
          ? state?.appointment_process?.hours_count
          : 4,
        professionals_count: state?.appointment_process?.professionals_count
          ? state?.appointment_process?.professionals_count
          : 1,
        is_cleaning_materials: state?.appointment_process?.is_cleaning_materials
          ? state?.appointment_process?.is_cleaning_materials
          : false,
        is_auto_assign: state?.appointment_process?.is_auto_assign
          ? state?.is_auto_assign
          : true,
        cleaner_id: state?.appointment_process?.cleaners_id
          ? state?.cleaners_id
          : "",
        country_id: state?.appointment_process?.country_id
          ? state?.appointment_process?.country_id
          : localStorage.getItem("userCountryId"),
        holidayPrice: state?.appointment_process?.holiday_price
          ? state?.appointment_process?.holiday_price
          : 0,
        totalPrice: state?.appointment_process?.total_amount
          ? state?.appointment_process?.total_amount
          : 280,
        discountPrice: state?.appointment_process?.discount_price
          ? state?.appointment_process?.discount_price
          : 0,
        address_id: state?.appointment_process?.booking_address?._id
          ? state?.appointment_process?.booking_address?._id
          : "",
        address: state?.appointment_process?.booking_address?.address
          ? state?.appointment_process?.booking_address?.address
          : "",
        voucher: "",
        latitude: state?.appointment_process?.booking_address?.latitude
          ? state?.appointment_process?.booking_address?.latitude
          : 0.0,
        longitude: state?.appointment_process?.booking_address?.longitude
          ? state?.appointment_process?.booking_address?.longitude
          : 0.0,
        rhClub: localStorage.getItem("rhClub"),
        customer_service_id: state?.appointment_process?.customer_id
          ? state?.appointment_process?.customer_id
          : currentUser?.id,
        address_nickname: state?.appointment_process?.booking_address
          ?.address_nickname
          ? state?.appointment_process?.booking_address?.address_nickname
          : "",
        area: state?.appointment_process?.booking_address?.area
          ? state?.appointment_process?.booking_address?.area
          : "",
        address_type: state?.appointment_process?.booking_address?.address_type
          ? state?.appointment_process?.booking_address?.address_type
          : "",
        street: state?.appointment_process?.booking_address?.street
          ? state?.appointment_process?.booking_address?.street
          : "",
        building: state?.appointment_process?.booking_address?.building
          ? state?.appointment_process?.booking_address?.building
          : "",
        floor: state?.appointment_process?.booking_address?.floor
          ? state?.appointment_process?.booking_address?.floor
          : "",
        house_no: state?.appointment_process?.booking_address?.house_no
          ? state?.appointment_process?.booking_address?.house_no
          : "",
        Office_no: state?.appointment_process?.booking_address?.Office_no
          ? state?.appointment_process?.booking_address?.Office_no
          : "",
        apartment_no: state?.appointment_process?.booking_address?.apartment_no
          ? state?.appointment_process?.booking_address?.apartment_no
          : "",
        wallet_amount: state?.appointment_process?.wallet_amount
          ? state?.appointment_process?.wallet_amount
          : "",
        service_id: state?.appointment_process?.service_id?.isArray
          ? state?.appointment_process?.service_id[0]
          : typeof state?.appointment_process?.service_id === "string"
          ? [state?.appointment_process?.service_id]
          : state?.appointment_process?.service_id[0]
      }));
      setServiceInfo(state?.serviceDetails);
      localStorage.setItem(
        "serviceId",
        typeof state?.appointment_process?.service_id === "string"
          ? [state?.appointment_process?.service_id]
          : state?.appointment_process?.service_id[0]
      );
      setPendingStatus((prevState) => prevState + 1);
    }
    if (state?.home === true && state?.serviceDetails) {
      setPackageType(state?.ui_type);

      setCheckoutData((prevState) => ({
        ...prevState,
        service_id: isLoggedIn
          ? state?.serviceDetails?.isArray
            ? state?.serviceDetails[0]
            : state?.serviceDetails
          : [state?.serviceDetails?.id],
        isBookNow: false,
        ui_type: state?.ui_type,
        country_id: localStorage.getItem("userCountryId")
      }));
      setServiceInfo(state?.serviceDetails);
      localStorage.setItem(
        "serviceId",
        isLoggedIn ? state?.serviceDetails : [state?.serviceDetails?.id]
      );
    }
  }, [state]);

  useEffect(() => {
    if (isLoggedIn && checkoutData.page === 5) {
      loginSignupRef?.current?.handleLoginSignupModalHide();
    }
    if(isLoggedIn){
      setCheckoutData((prevState) => ({
        ...prevState,
        wayOfBooking: ""
      }));
    }
   
  }, [isLoggedIn]);

 

  async function updateCheckoutData(key, value) {
    setCheckoutData((prevState) => ({
      ...prevState,
      [key]: value
    }));
    // setTimeout(() => {
    if (
      key === "frequency_id" ||
      key === "hours_count" ||
      key === "professionals_count" ||
      key === "is_cleaning_materials"
    ) {
      var values = {
        hours_count:
          key === "hours_count"
            ? value
            : checkoutData.hours_count
            ? checkoutData?.hours_count
            : 4,
        professionals_count:
          key === "professionals_count"
            ? value
            : checkoutData.professionals_count,
        is_cleaning_materials:
          key === "is_cleaning_materials"
            ? value
            : checkoutData.is_cleaning_materials,
        frequency_id:
          key === "frequency_id" ? value : checkoutData?.frequency_id,
        country_id: checkoutData?.country_id,
        customer_id: currentUser?.id
      };
      const actionUrl =
        baseUrl + `/appointment/calculate/frequency/appointment/price`;
      dispatch(
        getAppointmentPriceAction(
          actionUrl,
          values,
          setCheckoutData
        )
      );

      // const url = new URL(window.location.href);
      // url.searchParams.set(key, value);
      // window.history.replaceState(null, null, url);
    }
    // }, 500);

    setTimeout(() => {
      localStorage.setItem(
        "frequencyCheckoutData",
        JSON.stringify(checkoutData)
      );
    }, 1000);
  }

  const FormTitle = [
    "",
    t("address"),
    t("choose_your_frequency"),
    t("service_details"),
    t("almost_there"),
    t("payment")
  ];

  useEffect(() => {
    frequencyCheckoutData =
      localStorage.getItem("frequencyCheckoutData") &&
      localStorage.getItem("frequencyCheckoutData") !== "null"
        ? JSON.parse(localStorage.getItem("frequencyCheckoutData"))
        : false;
    if (frequencyCheckoutData) {
      setCheckoutData(frequencyCheckoutData);
    }

    let updateStep = params.get("step") ? parseInt(params.get("step")) : 1;

    if (
      frequencyAppointmentData &&
      frequencyAppointmentData.appointment_process
    ) {
      updateStep = frequencyAppointmentData.appointment_process + 1;
    }

    setCheckoutData((prevState) => ({
      ...prevState,
      ["page"]: updateStep,
      ["hours_count"]: 4,
      ["professionals_count"]: 1,
      ["is_cleaning_materials"]: false,
      ["voucher"]: "",
      frequency_id: "",
      frequency_name: "",
      country_id: localStorage.getItem("userCountryId"),
      appointment_date: moment().format("YYYY-MM-DD"),
      start_time_between: ""
    }));

    const url = new URL(window.location.href);

    url.searchParams.set("step", updateStep);
    window.scrollTo(0, 0);
    window.history.replaceState(null, null, url);
  }, []);

  async function submitAppointment(data) {
    let apiUrl = "";
    if (checkoutData?.page === 1) {
      const data = {
        address_id: checkoutData?.address_id,
        is_home_cleaning: true,
        service_id: [serviceInfo?.id],
        country_id: checkoutData?.country_id,
        appointment_process: 1,
        appointment_id: ""
      };
      apiUrl =
        process.env.REACT_APP_LOCAL_API_URL + "/appointment/create/address";
    } else if (checkoutData.page === 2) {
      apiUrl =
        process.env.REACT_APP_LOCAL_API_URL + "/appointment/create/frequency";
    } else if (checkoutData.page === 3) {
      apiUrl =
        process.env.REACT_APP_LOCAL_API_URL + "/appointment/create/service";
    } else if (checkoutData.page === 4) {
      apiUrl = process.env.REACT_APP_LOCAL_API_URL + "/appointment/create/time";
    } else if (checkoutData.page === 5) {
      apiUrl =
        process.env.REACT_APP_LOCAL_API_URL + "/appointment/create/payment";
    }
  }

  return (
    <>
      <div className="stepsFromPage">
        <Header />
        <ModifiedToastContainer />
        <LoginSignupModal ref={loginSignupRef} />
        {loading ? <Loader /> : ""}
      
        {checkoutData?.page === 6 ? (
          // <div className="container">
          <>
            <SuccessFullPayment
              checkoutData={checkoutData}
              setCheckoutData={setCheckoutData}
              setBookingData={setBookingData}
              setBookingSuccessModal={setBookingSuccessModal}
            />
            <SuccessBooking
              bookingSuccessModal={bookingSuccessModal}
              appointment={appointment}
              serviceInfo={serviceInfo}
              checkoutData={checkoutData}
              bookingData={bookingData}
            />
          </>
        ) : (
          <>
            <div className="container">
              <div className="pt-5">
                <div className="heading">
                  <h2>
                    {checkoutData?.page === 5 &&
                    checkoutData?.wayOfBooking === "withoutLogin"
                      ? FormTitle[1]
                      : FormTitle[checkoutData.page]}
                  </h2>
                  <p>{t("all_bookings")}</p>
                </div>
              </div>
              <div className="">
                <Steps checkoutData={checkoutData} />
              </div>
              {(() => {
                if (
                  isLoggedIn &&
                  checkoutData?.wayOfBooking !== "withoutLogin"
                ) {
                  if (checkoutData.page === 1) {
                    return (
                      <>
                       <Address
                        checkoutData={checkoutData}
                        updateCheckoutData={updateCheckoutData}
                        appointment={appointment}
                        setCheckoutData={setCheckoutData}
                        serviceInfo={serviceInfo}
                      />
                      </>
                     
                    );
                  } else if (checkoutData.page === 2) {
                    return (
                      <Frequency
                        checkoutData={checkoutData}
                        updateCheckoutData={updateCheckoutData}
                        appointment={appointment}
                        setCheckoutData={setCheckoutData}
                        serviceInfo={serviceInfo}
                      />
                    );
                  } else if (checkoutData.page === 3) {
                    return (
                      <ServiceDetails
                        checkoutData={checkoutData}
                        packageType={packageType}
                        updateCheckoutData={updateCheckoutData}
                        appointment={appointment}
                        setCheckoutData={setCheckoutData}
                        serviceInfo={serviceInfo}
                      />
                    );
                  } else if (checkoutData.page === 4) {
                    return (
                      <DateTime
                        checkoutData={checkoutData}
                        updateCheckoutData={updateCheckoutData}
                        appointment={appointment}
                        setCheckoutData={setCheckoutData}
                        serviceInfo={serviceInfo}
                      />
                    );
                  } else if (checkoutData.page === 5) {
                    return (
                      <Payment
                        checkoutData={checkoutData}
                        updateCheckoutData={updateCheckoutData}
                        setCheckoutData={setCheckoutData}
                        serviceInfo={serviceInfo}
                      />
                    );
                  }
                } else {
                  if (checkoutData.page === 2) {
                    return (
                      <Frequency
                        checkoutData={checkoutData}
                        updateCheckoutData={updateCheckoutData}
                        appointment={appointment}
                        setCheckoutData={setCheckoutData}
                        serviceInfo={serviceInfo}
                        isLoggedIn={isLoggedIn}
                        isLocation={isLocation}
                        setIsLocation={setIsLocation}
                      />
                    );
                  } else if (checkoutData.page === 3) {
                    return (
                      <ServiceDetails
                        checkoutData={checkoutData}
                        packageType={packageType}
                        updateCheckoutData={updateCheckoutData}
                        appointment={appointment}
                        setCheckoutData={setCheckoutData}
                        serviceInfo={serviceInfo}
                        currentUser={currentUser}
                      />
                    );
                  } else if (checkoutData.page === 4) {
                    return (
                      <DateTime
                        checkoutData={checkoutData}
                        updateCheckoutData={updateCheckoutData}
                        appointment={appointment}
                        setCheckoutData={setCheckoutData}
                        serviceInfo={serviceInfo}
                      />
                    );
                  } else if (
                    checkoutData.page === 5 &&
                    checkoutData?.wayOfBooking === "withoutLogin"
                  ) {
                    return (
                      <>
                        {!isLoggedIn && !isAddressAdded ? (
                          <LoginSignupStep
                            checkoutData={checkoutData}
                            setCheckoutData={setCheckoutData}
                            isAddressAdded={isAddressAdded}
                            setIsAddressAdded={setIsAddressAdded}
                          />
                        ) : (
                          // <LoginSignupModal
                          //   ref={loginSignupRef}
                          //   setCheckoutData={setCheckoutData}
                          //   serviceInfo={serviceInfo}
                          //   isLoggedIn={isLoggedIn}
                          //   from={"appointmentBooking"}
                          // />
                          <>
                            <Address
                              checkoutData={checkoutData}
                              updateCheckoutData={updateCheckoutData}
                              appointment={appointment}
                              setCheckoutData={setCheckoutData}
                              serviceInfo={serviceInfo}
                              fromLogout={true}
                              bookingType={"withoutLogin"}
                              isLoggedIn={isLoggedIn}
                              isAddressAdded={isAddressAdded}
                            />
                          </>
                        )}
                      </>
                    );
                  }
                }
              })()}
              {!isLoggedIn && checkoutData?.page === 2 && !isLocation ? (
                <>
                  <div className="row servicePackage">
                    <div className="col-lg-7">
                      <div className="actionButton actionBTn">
                        <button
                          className="btn btn-primary"
                          //  onClick={() => nextButtonClick(checkoutData)}
                          disabled={!isLocation}
                        >
                          {t("next")}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              ) : checkoutData?.page === 5 &&
                checkoutData?.wayOfBooking === "withoutLogin" &&
                !isLoggedIn &&
                !isAddressAdded ? null : (
                <NextBackButton
                  checkoutData={checkoutData}
                  updateCheckoutData={updateCheckoutData}
                  submitAppointment={submitAppointment}
                  loginSignupRef={loginSignupRef}
                  setCheckoutData={setCheckoutData}
                  serviceInfo={serviceInfo}
                  setBookingSuccessModal={setBookingSuccessModal}
                  handleBookingSuccessModalShow={bookingSuccessModal}
                  setBookingData={setBookingData}
                  isLoggedIn={isLoggedIn}
                  isLocation={isLocation}
                  setIsLocation={setIsLocation}
                />
              )}
            
            </div>
            <SuccessBooking
              bookingSuccessModal={bookingSuccessModal}
              appointment={appointment}
              serviceInfo={serviceInfo}
              checkoutData={checkoutData}
              bookingData={bookingData}
            />
          </>
        )}

        <Footer />
      </div>
    </>
  );
};

export default FrequencyCheckout;
