import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";
import {useCountryCurrency} from "../../../../helpers/useCountryCurrency";
const moment = require("moment");

export const CheckoutSummary = ({ checkoutData, cart, updateCheckoutData ,appointment }) => {
  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const { t } = useTranslation();
  const countryCurrency = useCountryCurrency(localStorage.getItem("userCountryId"));    
  const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;
   
  useEffect(() => {
    let price = 0;
    if(cart?.length > 0){
      updateCheckoutData("cart",cart);
      cart.map((item, key) => {
        let itemPrice = 0;
        itemPrice = ((item.prices.new_price) ?  item.prices.new_price:  item.prices.price) * item.item_count;
        price = price + itemPrice;
      });
      
      updateCheckoutData('totalPrice',price);
      updateCheckoutData('price',price);
    }
  }, [cart]);
  localStorage.setItem("addressId",checkoutData.address_id)
  return (
    <>
      <div className="card cardDetails">
        <div className="card-content ">
          <p>{t('address')}</p>  
          {/* <p>{(checkoutData && checkoutData.address) ? checkoutData.address : ''}</p> */}
        </div>
        <div className="card-content ">
          <p>{checkoutData?.is_home_cleaning?t('service_details'):t('service_details_non_cleaning')}</p>  
          <ul>
              {checkoutData?.cart?.length > 0
                ? checkoutData.cart.map((packageItem, key) => {
                    return (
                      <li key={key}>
                        {packageItem.item_count} X{" "}
                        {currentLanguage === "en"
                          ? packageItem.name
                          : packageItem.name_arabic}
                        <span>
                          {/* {countryCurrency[0].currency}{" "} */}
                          {((packageItem.prices.new_price) ? packageItem.prices.new_price : packageItem.prices.price)}
                        </span>
                      </li>
                    );
                  })
                : ""}
          </ul>
        </div>
        <div className="card-content ">
          <p>{t('date_and_time')}</p> 
          <ul>
            <li> 
              {t('date')} <span>{(checkoutData.appointment_date) ? moment(checkoutData.appointment_date, ["YYYY-MM-DD"]).format("dddd, MMMM DD") : ''}</span>
            </li>
            <li>
              {t('start_time')} <span>{(checkoutData.start_time_between) ? checkoutData.start_time_between : ''}</span>
            </li>
          </ul>
        </div>
        <div className="card-content ">
          <p>{t('where_should_serve')} </p>  
          <ul>
            <li>
            { checkoutData?.address
                ?  checkoutData?.address
                : ""}
            </li>
          </ul>
        </div>
        <div className="card-content ">
          <p>{ t('price_details') }</p>  
          <ul>
            <li> 
              { t('price') } <span>
                {/* {countryCurrency[0].currency} */}
                 {Number((checkoutData && checkoutData.price) ? checkoutData.price : 0).toFixed(2)}</span>
            </li>
            {/* {(() => {
              if (checkoutData.rushSlot > 0) {
                return  <li>
                { t('rush_slot') } <span>QAR {Number(checkoutData.rushSlot).toFixed(2)}</span>
              </li>;
              }
            })()}*/}
            {(() => {
              if (checkoutData.holidayPrice > 0) {
                return  <li>
                { t('holiday_price') } <span>{checkoutData?.currency} {Number(checkoutData.holidayPrice).toFixed(2)}</span>
              </li>;
              }
            })()} 
          
             <li>
            
               <li>
               { checkoutData?.rhClub === true && checkoutData?.rh_discount>0 ?
               <> {t('freecredit')}  <span>{checkoutData?.currency} {Number((checkoutData && checkoutData?.rh_discount) ? checkoutData?.rh_discount : 0).toFixed(2)}</span></>
              :""}</li>
              
            </li>
            <li>
               { checkoutData?.isWallet === true  ?
               <> {t('wallet_payment')}  <span>{checkoutData?.currency} {Number((checkoutData && checkoutData?.wallet_amount) ? checkoutData?.wallet_amount : 0).toFixed(2)}</span></>
              :""}</li>
              
          
              {(() => {
              if (checkoutData.vatPrice > 0) {
                return  <li>
              { t('vat') } <span>
                {/* {countryCurrency[0].currency}  */}
                {Number((checkoutData && checkoutData.vatPrice) ? checkoutData.vatPrice : 0).toFixed(2)}</span>
                </li>;
              }
            })()} 
             {(() => {
              if (checkoutData.discountPrice > 0) {
                return  <li>
              { t('vipDiscount') } <span>
                {/* {countryCurrency[0].currency} */}
                 {Number((checkoutData && checkoutData.discountPrice) ? checkoutData.discountPrice : 0).toFixed(2)}</span>
                 </li>;
              }
            })()} 
            <li>
              { t('total') } <span className="total">
                {/* {countryCurrency[0].currency} */}
                {" "}

              {(() => {
                let price = parseInt(checkoutData.totalPrice)
                 if (checkoutData.rushSlot > 0) {
                  price = price + checkoutData.rushSlot;
                }
                // if(checkoutData?.holidayPrice > 0 ){
                //   price = price + checkoutData?.holidayPrice;
                // } 
                // if(checkoutData?.discountPrice > 0 ){
                //   price = price - checkoutData?.discountPrice;
                // }
                // if(checkoutData?.rh_discount > 0  && checkoutData?.rhClub === true ) {
                //   price = price - checkoutData?.rh_discount;
                // }
                if(checkoutData?.wallet_amount > 0  && checkoutData?.isWallet === true  ) {
                  price = price - checkoutData?.wallet_amount;
                }
                return Number(price).toFixed(2);
              })()}
              
              </span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
export default CheckoutSummary;
