import React,{useEffect} from "react";
import Loader from "./Loader";

const Deeplink = () => {
    const windowUrl = window.location.search;
    const params = new URLSearchParams(windowUrl);
    useEffect(() => {
        // setTimeout(() => {
            let voucher = params.get('voucher');
            if(voucher && voucher !== ''){
                window.location.href = process.env.REACT_APP_INVITE_URL+'/frequency/checkout/details?step=2&hours_count=4&professionals_count=1&is_cleaning_materials=true&frequency_id=6253b87b59af23d16f5a584a&voucher='+voucher;
          
            }else{
                window.location.href = process.env.REACT_APP_INVITE_URL;
            }
        // }, 1000);
      }, [params]);
    return (
        <div>
            <Loader/>
        </div>
    )
};

export default Deeplink;
