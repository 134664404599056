import React, {useState, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {getHomeBannerAction} from "../actions/homeAction";
// import Loader from "./Loader";
import LoginSignupModal from "./LoginSignup/LoginSignupModal";

function Banner() {
  const getAllServicesState = useSelector((state) => state.homeReducer);
  const {services} = getAllServicesState;
  const {t} = useTranslation();
  const loginSignupRef = useRef();
  let navigate = useNavigate();
  const [serviceDetailId, setServiceDetailId] = useState("");
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const currentToken = localStorage.getItem("currentToken")
    ? JSON.parse(localStorage.getItem("currentToken"))
    : null;
  useEffect(() => {
    if (services?.length > 0) {
      services.map((service, index) => {
        if (service.name === "HOME CLEANING") {
          setServiceDetailId(service._id);
        }
      });
    }
  });

  async function viewServiceDetails(serviceId, home_banner) {
    if (currentUser) {
      navigate("/frequency/checkout/details?step=1", {
        state: {
          serviceId: "62385633517c11eaf7568824",
          isBookNow: true,
          isLogin: true,
          ui_type: "Frequency"
        }
      });
    } else {
      // console.log("home_banner", home_banner);
      navigate(`/frequency/checkout/details?step=${2}`, {
        state: {
          serviceId: "62385633517c11eaf7568824",
          isBookNow: true,
          isLogin: false,
          ui_type: "Frequency"
        }
      });
    }
  }

  const currentLanguage = localStorage.getItem("currentLanguage")
    ? localStorage.getItem("currentLanguage")
    : "en";
  const getHomeBannerState = useSelector((state) => state.bannerReducer);
  const {loading, home_banner} = getHomeBannerState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = useEffect(() => {
    const countryID = localStorage.getItem("userCountryId");
    let listMainUrl =
      baseUrl +
      "/banner/find/by/country/" +
      (localStorage.getItem("userCountryId")
        ? localStorage.getItem("userCountryId")
        : "");
    if (countryID && listMainUrl) {
      dispatch(getHomeBannerAction(listMainUrl));
    }
  }, []);

  return (
    <>
      <LoginSignupModal ref={loginSignupRef} />
      <section className=" bannerSection w-full">
        <div className="container">
          <div className="bannerRow">
            <div className="bannerContent">
              <h1>
                {currentLanguage === "en"
                  ? home_banner?.description
                  : home_banner?.description_arabic}
              </h1>
              {/*<h1>{t("banner_body_text_2")}</h1>*/}
              {home_banner?.Discount1 !== null && (
                <button
                  className="btn btn-primary rounded"
                  onClick={(e) =>
                    viewServiceDetails(
                      home_banner?.Discount1?.service_id ===
                        "62385633517c11eaf7568824"
                        ? home_banner?.Discount1?.service_id
                        : "62385633517c11eaf7568824",
                      home_banner,
                      home_banner?.Discount1
                    )
                  }
                >
                  {t("book_now_btn")}
                </button>
              )}
            </div>
            <div className="bannerwelcome">
              <h2>
                {currentToken ? t("welcome_back") : ""}
                {currentToken ? ", " : ""}
                {currentToken ? currentUser.name : ""}
              </h2>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Banner;
