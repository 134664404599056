/* eslint-disable jsx-a11y/alt-text */
import React, { useState,useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import { getCovidGLDataAction } from "../../../../actions/tcDataAction";
import {postBookAppointmentAction} from "../../../../actions/appointmentAction";
import AddressAddModal from  "../../../my_account/Address/AddressAddModal";

const moment = require("moment");

const NextBackButton = ({ checkoutData, cart, updateCheckoutData, confirmAppointmentPay,  addressInformation, loginSignupRef, error, setCheckoutData}) => {

  const getCovidGLState = useSelector(state => state.getTCReducer)
  const { loading, covid_guidlines } = getCovidGLState;
  const dispatch = useDispatch();
  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  let listUrl = baseUrl + "/staticPage/info/covid";
  const currentLanguage = localStorage.getItem('currentLanguage') ? localStorage.getItem('currentLanguage') : 'en';
  const currentUser = (localStorage.getItem('currentUser') && localStorage.getItem('currentUser') !== "null") ? JSON.parse(localStorage.getItem('currentUser')) : false;
  const setAddAddressRef = useRef();

  useEffect(() => {
      dispatch(getCovidGLDataAction(listUrl,{}));
  }, [listUrl,dispatch]);
 
  const [bookingSummaryInfoShow, setBookingSummaryInfoShow] = useState(false);
  const handleSetBookingSummaryInfoShow = () => setBookingSummaryInfoShow(true);
  const handlesetBookingSummaryInfoHide = () => setBookingSummaryInfoShow(false);

  const [covidGLInfoShow, setCovidGLInfoShow] = useState(false);
  const handleSetCovidGLInfoShow = () => setCovidGLInfoShow(true);
  const handleSetCovidGLInfoHide = () => setCovidGLInfoShow(false);
  const getCleanerTimeAvailabilitySlot = useSelector(
    (state) => state.cleanerTimeAvailabilityReducer
  );
  const { available_time, selectedCleaner  } = getCleanerTimeAvailabilitySlot;  
  let values111={ 
    address_id: checkoutData.address_id,
    is_home_cleaning: checkoutData.is_home_cleaning,
    service_id: checkoutData.service_id,
    country_id:localStorage.getItem('userCountryId'),
     customer_id: currentUser?._id
  };
  function changeStep(e,step,page) {
   e.preventDefault();
    var updateStep = checkoutData.page;
    if(step === "Next" && page){
      let appointmentData = {
        1:{ordered_item: checkoutData?.cart,
      service_category_id: [checkoutData?.cart[0]?.sub_service_id],
      service_package_id: [checkoutData?.cart[0]?._id],
      appointment_process: 3,
      customer_id: currentUser?._id,
       totalAmount:checkoutData?.totalPrice ,
      },
      2:{appointment_date_start: checkoutData.appointment_date_start,
        start_time_between: checkoutData.start_time_between,
        appointment_process: 3,
        is_auto_assign: "true",
        hours_count: checkoutData.hours_count,
        address_id: checkoutData.address_id,
        cleaner_id : selectedCleaner ? selectedCleaner: ''
      },
      3:{
        payment_mode: checkoutData.payment_mode !== ''? checkoutData.payment_mode :"",
        discount_type: checkoutData.discount_type !== ''? checkoutData.discount_type :"",
        voucher:checkoutData.voucher,
        promo_code:checkoutData.promo_code,
        source_id:checkoutData.source_id,
        customer_id: currentUser?._id,
        pay_type: checkoutData.pay_type !== ''? checkoutData.pay_type :"" ,
        transaction_no :"",
        isWallet: checkoutData.isWallet ? checkoutData.isWallet:"",
        appointment_process: 4,
        appointment_date_start: checkoutData.appointment_date_start
      }
    }
    
    //   // appointmentData[page]['appointment_process'] = page;
    if(currentUser){
      if(page === 1){
        addressInformation(values111);
      
        setTimeout(()=>{
          appointmentData[page]['appointment_id'] = localStorage.getItem('bookAppointmentId') && localStorage.getItem('bookAppointmentId') !== undefined ? localStorage.getItem('bookAppointmentId') : "";
          confirmAppointmentPay(appointmentData[page]);

        },5000);
      } else {
        appointmentData[page]['appointment_id'] = localStorage.getItem('bookAppointmentId') && localStorage.getItem('bookAppointmentId') !== undefined ? localStorage.getItem('bookAppointmentId') : "";
        confirmAppointmentPay(appointmentData[page]);
      }
    }
  }
 
    if(step === "Next" ){
    
      if(page === 2 && currentUser){
       
          // handleSetCovidGLInfoShow();
      }
      
      if(checkoutData.actionStatus && currentUser) {  
       updateStep = updateStep <= 3 ? updateStep + 1 : updateStep;
       } else {
         updateStep = updateStep <= 3 ? updateStep + 0 : updateStep;
       }
       if(currentUser === false) {
        updateStep = updateStep <= 3 ? updateStep + 1 : updateStep;
       }
      if(updateStep === 4){
       
        // confirmAppointmentPay();
      }
    }else{
      if(checkoutData.page === 5){setCheckoutData({...checkoutData, ['payment_mode']:''})}
      updateStep = updateStep - 1;
    } 
    updateCheckoutData('page',updateStep);
    const url = new URL(window.location.href);
    url.searchParams.set('step', updateStep);
    window.history.replaceState(null, null, url);
  }
  
  const { t } = useTranslation();
  return (
    <>
      <div className="row servicePackage">
        <div className="col-lg-7">
          <div className="actionButton">
            <button
              disabled={checkoutData.page === 1}
              className=" btn-back"
              onClick={(e) => changeStep(e,"Previous",checkoutData.page)}
            >
              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/back.png.webp" />
            </button>
            <div className="priceTotal">
              <p>{t('total')}</p> 
              <h3>
                {checkoutData.currency} {(checkoutData && checkoutData.totalPrice) ? checkoutData.totalPrice : 0}
                <span
                  className="fa fa-angle-up ms-2"
                  onClick={() => handleSetBookingSummaryInfoShow()}
                ></span>
              </h3>
            </div>
            {(() => {
              let showButton = true;
              if (checkoutData.page === 1) {
                if(cart?.length === 0){
                  showButton = false;
                }
                
              }
             
              if(checkoutData.page === 2 && (checkoutData.appointment_date === "" || checkoutData.start_time_between === "" )&& checkoutData.actionStatus){
                showButton = false;
                // if(currentUser && checkoutData.actionStatus) {
                //   showButton =true;
                // } else {
                //   showButton = false;
                // }
                if(currentUser === false && checkoutData.latitude <= 0){
                  setAddAddressRef.current.handleAddress()
                }
                
              }
             
              // if(checkoutData.page === 3 && (!checkoutData.qnb_customer_id || checkoutData.qnb_customer_id === "")){
              //   showButton = false;
              // }
              
              if (checkoutData.page === 3 ) {
                if(currentUser ){
                
                  if(checkoutData.payment_mode !== "" && checkoutData.payment_terms_condition) {
                    showButton =true
                   
                  } else {
                    showButton = false 
                  }
                  
                } else {
                  loginSignupRef.current.handleLoginSignupModalShow();
                }
              
              }

              if (showButton) {
                return <button
                  className="btn btn-primary"
                  onClick={(e) => changeStep(e,"Next",checkoutData.page)}
                >
                  {(checkoutData.page === 3 || checkoutData.page === 4) ? 
                  
                  t('complete') : t('next')}
              </button>
              }
            })()}
          </div>
        </div>
      </div>

      <Modal
        show={covidGLInfoShow}
        onHide={handleSetCovidGLInfoHide}
        id="covidModal"
        backdrop="static"
        className="covidModal"
      >
        <Modal.Header>  
          <Modal.Title>{ t('health_declaration') }</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="modal-body p-0 ">
              < img src="https://dvdljkkxpxqo3.cloudfront.net/images/covid.jpg.webp"
                className="w-full "
              />
              <div className="p-4">
                 {covid_guidlines ?
                 <div className="accordion-body" dangerouslySetInnerHTML={ { __html: (currentLanguage === "en" ? covid_guidlines.content : covid_guidlines.content_arabic)}}>
                 </div>
                 : ""}
              </div>
              <div className="p-4 border-top mt-2">
                <button className="btn btn-primary" onClick={() => handleSetCovidGLInfoHide()}>{ t('i_confirm') }</button>
              </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={bookingSummaryInfoShow}
        onHide={handlesetBookingSummaryInfoHide}
        id="bookingSummary"
        className="bookingSummary"
      >
        <Modal.Header closeButton>  
          <Modal.Title>{t('booking_summary')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="modal-body p-0 ">
            <div className="card ">
              <div className="card-content ">
                <p>{t('city')}</p> 
                <p>Duhail</p>
              </div>
              <div className="card-content ">
                <p>{checkoutData?.is_home_cleaning?t('service_details'):t('service_details_non_cleaning')}</p> 
                <ul>
                <li>  
                  {t('frequency')} <span>{checkoutData.frequency_name}</span>
                </li>
                <li>  
                  {t('duration')} <span>{checkoutData.hours_count} {t('hours')}</span>
                </li>
                <li> 
                  {t('no_of_professionals')} <span>{checkoutData.professionals_count}</span>
                </li>
                <li> 
                  {t('material')} <span>{checkoutData.is_cleaning_materials ? t('yes') : t('no')}</span>
                </li>
                </ul>
              </div>  
              <div className="card-content ">
                <p>{t('date_and_time')}</p>
                <ul>
                  <li> 
                    {t('professional')} <span>
                    <div className="is-flex">
                        {(checkoutData.professional_full_name) ? checkoutData.professional_full_name : ''}
                        {(checkoutData.professional_full_name !== "Auto assign" && checkoutData.professional_full_name !== "" && checkoutData.professional_profile_image !== "") ? <img alt="" src={checkoutData.professional_profile_image} className="cleaner-image en" /> : ""}
                    </div>
                    </span>
                  </li>
                  <li>  
                    {t('date')} <span>{(checkoutData.appointment_date) ? moment(checkoutData.appointment_date, ["YYYY-MM-DD"]).format("dddd, MMMM DD") : ''}</span>
                  </li>
                  <li>   
                    {t('start_time')} <span>{(checkoutData.start_time_between) ? checkoutData.start_time_between : ''}</span>
                  </li>
                </ul>
              </div>
              <div className="card-content ">
                <p>{t('address')} </p>  
                {/* <ul>
                  <li>
                  {(checkoutData && checkoutData.address) ? checkoutData.address : ''} 
                  </li>
                </ul> */}
              </div>
              <div className="card-content ">
                <p>{t('price_details')}</p>   
                <ul>
                  <li>  
                    {t('price')} <span>{checkoutData.currency} {(checkoutData && checkoutData.price) ? checkoutData.price : 0}</span>
                  </li>
                  <li> 
                    {t('vat')} <span>{checkoutData.currency} {(checkoutData && checkoutData.vatPrice) ? checkoutData.vatPrice : 0}</span>
                  </li>
                  <li>  
                    {t('discount')} <span>-{checkoutData.currency} {(checkoutData && checkoutData.discountPrice) ? checkoutData.discountPrice : 0}</span>
                  </li>
                  <li>  
                    {t('total')} <span className="total">{checkoutData.currency} {(checkoutData && checkoutData.totalPrice) ? checkoutData.totalPrice : 0}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <AddressAddModal ref={setAddAddressRef} updateCheckoutData={updateCheckoutData} />
    </>
  );
};

export default NextBackButton;
