import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, Link} from "react-router-dom";

const ProblemsNone = () => {
  const {t} = useTranslation();
  let navigate = useNavigate();
  const handlerRedirect = (e) => {
    e.preventDefault();
    navigate("../../components/SwipeSlider");
  };

  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const handelBook = (step) => {
    navigate(`/frequency/checkout/details?step=${step}`, {
      state: {
        serviceId: "62385633517c11eaf7568824",
        isBookNow: true,
        isLogin: currentUser ? true : false,
        ui_type: "Frequency"
      }
    });
  };
  return (
    <>
      <div className="problemsArea py-7">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="heading">
                <h2>{t("turning_your_99")}</h2>
                <h3>{t("we_know_you_are_busy")}</h3>
                <p>{t("rh_performs_your_tedious_tasks")}</p>
              </div>
              {/* <button className="btn btn-secondary btnLarge" onClick={(e) => document.getElementById('serviceShow').scrollIntoView()}>{t('rh_is_already_on_it')}</button> */}
              {currentUser ? (
                <p
                  onClick={() => handelBook(1)}
                  className="btn btn-secondary btnLarge"
                >
                  {t("rh_is_already_on_it")}
                </p>
              ) : (
                <p
                  onClick={() => handelBook(2)}
                  className="btn btn-secondary btnLarge"
                >
                  {t("rh_is_already_on_it")}
                </p>
              )}
            </div>
            <div className="col-lg-6 col-md-6 ">
              <img alt="" src="https://dvdljkkxpxqo3.cloudfront.net/images/family.webp" className="w-full" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProblemsNone;
