import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {authHeader, handlingNonauthErrors} from "../helpers/helpers";
import jQuery from "jquery";
const MySwal = withReactContent(Swal);

export const getCleanerTimeAvailability =
  (baseUrl, values, updateCheckoutData, t, setCheckoutData, checkoutData, from) =>
  (dispatch) => {
    const currentUser =
      localStorage.getItem("currentUser") &&
      localStorage.getItem("currentUser") !== "null"
        ? JSON.parse(localStorage.getItem("currentUser"))
        : false;

    dispatch({type: "GET_CLEANER_TIME_AVAILABILITY_REQUEST"});
    axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: values
    })
      .then((res) => {
        dispatch({
          type: "GET_CLEANER_TIME_AVAILABILITY_SUCCESS",
          payload: {
            available_time: res?.data?.available_time,
            selectedCleaner: res?.data?.selectedCleaner,
            status: res?.data?.status
            // monthSlots: res?.data?.monthSlots
          }
        });
        // console.log("GET_CLEANER_TIME_AVAILABILITY_SUCCESS", res.data, values);
        // console.log(
        //   "GET_CLEANER_TIME_AVAILABILITY_SUCCESS",
        //   checkoutData?.appointment_date
        // );
        // jQuery('.no_slot_available').html(t('no_slot_available'));
        // updateCheckoutData("holidayPrice", res.data.holiday_price);
        // updateCheckoutData("actionStatus", res.data.status);
        // updateCheckoutData("ActualCodCharges", res.data.codCharges);
    
        if(from != "Rescheduled"){
          setCheckoutData({
            ...checkoutData,
            ["appointment_date"]:values?.appointment_date
            ? values?.appointment_date
            : checkoutData?.appointment_date,
            ["appointment_date_start"]: values?.appointment_date
              ? values?.appointment_date+
              " " +  res.data?.available_time[0]?.time?.split("-")[0]
              : checkoutData?.appointment_date +
                " " +
                res.data?.available_time[0]?.time?.split("-")[0],
            ["start_time_between"]: res.data?.available_time[0]?.time ? res.data?.available_time[0]?.time  : "",
            ["ActualCodCharges"]: res.data.codCharges,
            ["actionStatus"]: res.data.status,
            ["holidayPrice"]: res.data.holiday_price,
            ["rushSlot"]: res.data?.available_time[0]?.totalSlotPrice,
            ['selectedCleaner']: res.data.selectedCleaner,
            ["professional_full_name"]: "Auto assign",
          });
        }
        
        //  updateCheckoutData('selectedCleaner', res.data.selectedCleaner)
      })
      .catch((err) => {
        console.log("Error: ", err.response);
        if(err?.response.data.message === "Requested number of pros are not available."){
          setCheckoutData((prevState) => ({
            ...prevState,
            ["start_time_between"]:"",
          }));
        }
        
        dispatch({type: "GET_CLEANER_TIME_AVAILABILITY_FAILED", payload: err});

        if (values.latitude !== 0) {
          handlingNonauthErrors(err);
        }
      });
  };
