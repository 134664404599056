import axios from "axios";
import {toast} from "react-toastify";
import {
  authHeader,
  handlingAuthErrors,
  handlingNonauthErrors
} from "../helpers/helpers";

export const getProfessionalListAction = (baseUrl, values) => (dispatch) => {
  dispatch({type: "GET_HOME_PROFESSIONAL_LIST_REQUEST"});
  axios({
    method: "GET",
    headers: authHeader(),
    url: baseUrl,
    data: values
  })
    .then((res) => {
      dispatch({
        type: "GET_HOME_PROFESSIONAL_LIST_SUCCESS",
        payload: {
          professionals: res.data.list
        }
      });
    })
    .catch((err) => {
      dispatch({type: "GET_HOME_PROFESSIONAL_LIST_FAILED", payload: err});
      handlingAuthErrors(err);
    });
};

export const getProfessionalInfoAction = (baseUrl, values) => (dispatch) => {
  dispatch({type: "GET_PROFESSIONAL_INFO_REQUEST"});
  axios({
    method: "GET",
    headers: authHeader(),
    url: baseUrl,
    data: values
  })
    .then((res) => {
      dispatch({
        type: "GET_PROFESSIONAL_INFO_SUCCESS",
        payload: {
          professional: res.data.user
        }
      });
    })
    .catch((err) => {
      dispatch({type: "GET_PROFESSIONAL_INFO_FAILED", payload: err});
      handlingAuthErrors(err);
    });
};
export const postBookAppointmentAction =
  (
    baseUrl,
    values,
    updateCheckoutData,
    setAppointment,
    handleBookingSuccessModalShow
  ) =>
  (dispatch) => {
    dispatch({type: "GET_BOOK_APPOINTMENT_REQUEST"});
    axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: values
    })
      .then((res) => {
        dispatch({
          type: "GET_BOOK_APPOINTMENT_SUCCESS",
          payload: {appointment: res?.data?.appointment},
          status: res?.data?.status
        });
        updateCheckoutData("appointment_id", res?.data?.appointment._id);
        updateCheckoutData(
          "address_id",
          res.data.appointment.booking_address._id
        );
        updateCheckoutData("actionStatus", res.data.status);
        // localStorage.setItem("bookAppointmentId", res.data.appointment._id);
        // localStorage.setItem(
        //   "frequencyAppointmentData",
        //   JSON.stringify(res.data.appointment)
        // );

        if (
          res.data.appointment.appointment_process === 5 &&
          res?.data?.appointment?.appointment_status === 1
        ) {
          // console.log("res?.data?.appointment", res?.data?.appointment);
          setAppointment(res?.data?.appointment);
          handleBookingSuccessModalShow();
          setTimeout(function () {
            localStorage.removeItem("frequencyAppointmentData");
            localStorage.removeItem("bookAppointmentId");
            localStorage.removeItem("frequencyCheckoutData");
            localStorage.removeItem("bookAppointmentDetails");
            localStorage.removeItem("frequencyTypeServiceDetailId");
            localStorage.removeItem("bookAppointmentCartData");
            localStorage.removeItem("serviceDetailId");
            updateCheckoutData("payment_mode", "");
            localStorage.removeItem("bookAppointmentId");
            localStorage.removeItem("serviceCheckoutData");
          }, 500);
        }
      })
      .catch((err) => {
        // setPageLoading(false);
        updateCheckoutData("actionStatus", false);
        dispatch({type: "GET_BOOK_APPOINTMENT_FAILED", payload: err});
        toast.error(err.response.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });

        // setPageLoading(false);

        handlingAuthErrors(err);
        // setTimeout(function () {
        //     window.location.href = "/my-account/appointments";
        // }, 1500);
        // handlingAuthErrors(err); */
      });
  };

export const getAppointmentListAction = (baseUrl, values) => (dispatch) => {
  dispatch({type: "GET_APPOINTMENT_LIST_REQUEST"});
  axios({
    method: "GET",
    headers: authHeader(),
    url: baseUrl,
    data: values
  })
    .then((res) => {
      dispatch({
        type: "GET_APPOINTMENT_LIST_SUCCESS",
        payload: {
          appointments: res.data.list,
          totalCount: res.data.totalPages
        }
      });
    })
    .catch((err) => {
      dispatch({type: "GET_APPOINTMENT_LIST_FAILED", payload: err});
      handlingAuthErrors(err);
    });
};

export const cancelAndRescheduleAppointmentAction =
  (baseUrl, values) => (dispatch) => {
    dispatch({type: "GET_APPOINTMENT_CANCEL_RESCHEDULE_REQUEST"});
    axios({
      method: "PUT",
      headers: authHeader(),
      url: baseUrl,
      data: values
    })
      .then((res) => {
        dispatch({
          type: "GET_APPOINTMENT_CANCEL_RESCHEDULE_SUCCESS",
          payload: {}
        });
        toast.success(res.data.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
        setTimeout(function () {
          localStorage.removeItem("appointmentDetailsId");
          window.location.href = "/my-account/appointments";
        }, 2000);
      })
      .catch((err) => {
        dispatch({
          type: "GET_APPOINTMENT_CANCEL_RESCHEDULE_FAILED",
          payload: err
        });
        handlingAuthErrors(err);
      });
  };

export const getAppointmentInfoAction = (baseUrl, values) => (dispatch) => {
  dispatch({type: "GET_APPOINTMENT_INFO_REQUEST"});
  axios({
    method: "GET",
    headers: authHeader(),
    url: baseUrl,
    data: values
  })
    .then((res) => {
      dispatch({
        type: "GET_APPOINTMENT_INFO_SUCCESS",
        payload: {
          appointment: res.data.appointment
        }
      });
    })
    .catch((err) => {
      dispatch({type: "GET_APPOINTMENT_INFO_FAILED", payload: err});
      handlingAuthErrors(err);
    });
};

export const getAppointmentPriceAction =
  (baseUrl, values, setCheckoutData) => (dispatch) => {
    dispatch({type: "GET_APPOINTMENT_PRICE_REQUEST"});

    axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: values
    })
      .then((res) => {
        dispatch({
          type: "GET_APPOINTMENT_PRICE_SUCCESS",
          payload: {
            totalPrice: res.data.totalPrice
          }
        });
        setCheckoutData((prevState) => ({
          ...prevState,
          "totalPrice": res?.data?.totalPrice,
          "price": res.data.price - res.data.totalMaterialPrice,
          "totalMaterialPrice": res.data.totalMaterialPrice,
          "discountPrice":res.data.discount,
          "frequencyDiscountPrice": res.data.discount,
          "vipDiscountPrice":res.data.vipDiscountPrice
        }));
       
        //   updateCheckoutData("totalPrice", res.data.totalPrice);
        //   updateCheckoutData("price", res.data.price - res.data.totalMaterialPrice);
        // updateCheckoutData("totalMaterialPrice",res.data.totalMaterialPrice);
        // updateCheckoutData("discountPrice",res.data.discount);
        // updateCheckoutData("frequencyDiscountPrice", res.data.discount);
        // updateCheckoutData("vipDiscountPrice", res.data.vipDiscountPrice);
        
      })
      .catch((err) => {
        dispatch({type: "GET_APPOINTMENT_PRICE_FAILED", payload: err});
        handlingNonauthErrors(err);
      });
  };

export const removeCartAppointmentAction =
  (baseUrl, handleAppointmentCartModalClose) => (dispatch) => {
    dispatch({type: "GET_REMOVE_CART_APPOINTMENT_REQUEST"});
    axios({
      method: "GET",
      headers: authHeader(),
      url: baseUrl,
      data: {}
    })
      .then((res) => {
        localStorage.removeItem("bookAppointmentId");
        localStorage.removeItem("frequencyTypeServiceDetailId");
        handleAppointmentCartModalClose();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        dispatch({type: "GET_REMOVE_CART_APPOINTMENT_SUCCESS", payload: {}});
      })
      .catch((err) => {
        dispatch({type: "GET_REMOVE_CART_APPOINTMENT_FAILED", payload: err});
        handlingAuthErrors(err);
      });
  };

export const uploadImagesAction = (allImages) => (dispatch) => {
  dispatch({
    type: "GET_APPOINTMENT_PICTURES_SUCCESS",
    payload: {
      uploadImages: allImages
    }
  });
};
export const uploadAppointmentAction = (baseUrl, values) => (dispatch) => {
  let currentToken = localStorage.getItem("currentToken")
    ? JSON.parse(localStorage.getItem("currentToken"))
    : null;
  dispatch({type: "GET_APPOINTMENT_CANCEL_RESCHEDULE_REQUEST"});
  axios({
    url: baseUrl,
    data: values,
    method: "PUT",
    headers: {
      "x-auth-token": currentToken,
      "Content-Type": "multipart/form-data"
    }
  })
    .then((res) => {
      dispatch({
        type: "GET_APPOINTMENT_CANCEL_RESCHEDULE_SUCCESS",
        payload: {}
      });
      toast.success(res.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
      setTimeout(function () {
        // localStorage.removeItem("appointmentDetailsId");
        window.location.href = "/my-account/appointments";
      }, 2000);
    })
    .catch((err) => {
      dispatch({
        type: "GET_APPOINTMENT_CANCEL_RESCHEDULE_FAILED",
        payload: err
      });
      handlingAuthErrors(err);
    });
};

export const unAuthAppointmentBooking = async (baseUrl, values) => {
  try {
    return await axios({
      method: "POST",
      headers: authHeader(),
      url: baseUrl,
      data: values
    });
  } catch (err) {
    console.log("error: ", err);
  }
};

export const pendingCancelAppointment = (baseUrl) => (dispatch) => {
  dispatch({type: "GET_APPOINTMENT_CANCEL_REQUEST"});
  axios({
    method: "GET",
    headers: authHeader(),
    url: baseUrl
  })
    .then((res) => {
      dispatch({
        type: "GET_APPOINTMENT_CANCEL_SUCCESS",
        payload: {
          message: res?.data?.message,
          status: res?.data?.status
        }
      });
      toast.success(res?.data?.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      });
    })
    .catch((err) => {
      dispatch({type: "GET_APPOINTMENT_CANCEL_FAILED", payload: err});
      handlingAuthErrors(err);
    });
};
