import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {useTranslation} from "react-i18next";
import {useCountryCurrency} from "../../../../helpers/useCountryCurrency";
// import {useSelector} from "react-redux";
const moment = require("moment");

export const SuccessBooking = ({
  bookingSuccessModal,
  appointment,
  bookingData
}) => {
  const {t} = useTranslation();
  const countryCurrency = useCountryCurrency();
  const [currency, setCurrency] = useState();
  const [isCashData, setIsCashData] = useState();
  useEffect(() => {
    if (countryCurrency?.length > 0) {
      setCurrency(countryCurrency[0]?.currency);
    }
  }, [countryCurrency]);
  return (
    <>
      <Modal
        show={bookingSuccessModal}
        id="bookingSuccess"
        className="bookingSuccess"
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{t("appt_successfully_booked")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="successPage">
            {/* {console.log("booking success", bookingData)} */}
            <div className="successCard ">
              <span className="fa fa-check checkicon"></span>
              <div className="mt-2 mb-2">
                <h2>{t("order_placed_title")}</h2>
              </div>
              <div className="card-content ">
                <h3>{t("details")}</h3>
                <ul>
                  <li>
                    {t("reference_code")}{" "}
                    <span>{bookingData?.appointment_no}</span>
                  </li>
                  <li>
                    {t("details")}{" "}
                    <span>
                      {bookingData?.FrequencyDetails
                        ? bookingData?.FrequencyDetails?.package_name
                        : ""}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="card-content ">
                <h3>{t("date_and_time")}</h3>
                <ul>
                  <li>
                    {t("date")}{" "}
                    <span>
                      {bookingData?.appointment_date_start
                        ? moment(bookingData?.appointment_date_start).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </span>
                  </li>
                  <li>
                    {t("start_time")}{" "}
                    <span>{bookingData?.start_time_between}</span>
                  </li>
                </ul>
              </div>
              <div className="card-content ">
                <h3>{t("address")}</h3>
                <ul>
                  <li>
                    {t("address")}
                    <span>{bookingData?.booking_address?.address}</span>
                  </li>
                </ul>
              </div>
              <div className="card-content ">
                <h3>{t("price_details")}</h3>
                <ul>
                  <li>
                    {t("payment_mode")}{" "}
                    <span>
                      {(() => {
                        let cardBYCard = "";
                        let freeCredit = "";
                        if (parseInt(bookingData?.total_amount) > 0) {
                          if (
                            bookingData.payment_mode === "card" ||
                            bookingData.payment_mode === "Card" ||
                            bookingData.payment_mode === ""
                          ) {
                            cardBYCard = t("credit_card");
                          } else if (
                            bookingData.payment_mode === "online" ||
                            bookingData.payment_mode === ""
                          ) {
                            cardBYCard = t("online");
                          } else {
                            cardBYCard = t("cash");
                          }
                        }

                        if (parseInt(bookingData?.credit_amount) > 0) {
                          freeCredit = t("free_credit");
                        }
                        return (
                          cardBYCard +
                          (freeCredit !== "" && cardBYCard !== "" ? "/" : "") +
                          freeCredit
                        );
                      })()}
                    </span>
                  </li>
                  <li>
                    {/* {isCashData.isCash && isCashData.price > 0 ? */}
                    <>
                      {t("price")}{" "}
                      <span>
                        {currency}{" "}
                        {bookingData?.FrequencyDetails.package_name ===
                        "One-off"
                          ? bookingData?.total_order_service_total -
                            (bookingData?.total_order_cleaning_total > 0
                              ? bookingData?.total_order_cleaning_total
                              : 0)
                          : bookingData?.total_order_service_total > 0 &&
                            bookingData?.FrequencyDetails.package_name ===
                              "Weekly"
                          ? bookingData?.total_order_service_total -
                            (bookingData?.total_order_cleaning_total > 0
                              ? bookingData?.total_order_cleaning_total
                              : 0)
                          : bookingData?.total_order_service_total -
                            (bookingData?.total_order_cleaning_total > 0
                              ? bookingData?.total_order_cleaning_total
                              : 0)}
                      </span>
                    </>

                    {/* :<>{t('price')} <span>{currency} {appointment?.order_total}</span></>
                    } */}
                  </li>

                  {bookingData?.per_wallet_amount > 0 && (
                    <li>
                      <>
                        {t("wallet_payment")}{" "}
                        <span>
                          {currency}{" "}
                          {bookingData?.per_wallet_amount
                            ? bookingData?.per_wallet_amount
                            : bookingData?.wallet_amount}
                        </span>
                      </>
                    </li>
                  )}

                  {bookingData?.is_cleaning_materials && (
                    <li>
                      {t("materialPrice")}{" "}
                      <span>{bookingData?.total_order_cleaning_total} </span>
                    </li>
                  )}
                  {bookingData?.cod_charges > 0 && (
                    <li>
                      <>
                        {t("codCharge")}{" "}
                        <span>
                          {currency}{" "}
                          {bookingData?.total_cod_charges
                            ? bookingData?.total_cod_charges
                            : bookingData?.total_cod_charges}
                        </span>
                      </>
                    </li>
                  )}
                  {bookingData?.promoCodeUsed && (
                    <li>
                      <>
                        {t("voucher")} <span>{bookingData?.promoCodeUsed}</span>
                      </>
                    </li>
                  )}

                  {(() => {
                    if (
                      bookingData?.slot_price &&
                      bookingData?.slot_price > 0
                    ) {
                      return (
                        <li>
                          {t("rush_slot")}{" "}
                          <span>
                            {" "}
                            {currency} {bookingData?.slot_price}
                          </span>
                        </li>
                      );
                    }
                    if (
                      bookingData?.mainPayment?.credit_amount &&
                      bookingData?.mainPayment?.credit_amount > 0
                    ) {
                      return (
                        <li>
                          {t("free_credit")}{" "}
                          <span>
                            -{currency}{" "}
                            {bookingData?.mainPayment?.credit_amount}
                          </span>
                        </li>
                      );
                    }
                    if (
                      bookingData?.holiday_price &&
                      bookingData?.holiday_price > 0
                    ) {
                      return (
                        <li>
                          {t("holiday_price")}{" "}
                          <span>
                            {" "}
                            {currency}{" "}
                            {bookingData?.FrequencyDetails.package_name ===
                            "Weekly"
                              ? bookingData?.total_holiday_price
                              : bookingData?.total_holiday_price}
                          </span>
                        </li>
                      );
                    }
                    if (bookingData?.vat && bookingData?.vat > 0) {
                      return (
                        <li>
                          {t("vat")} <span>{currency}</span>
                        </li>
                      );
                    }
                    // if (
                    //   bookingData?.total_discount &&
                    //   bookingData?.total_discount > 0
                    // ) {
                    //   return (
                    //     <li>
                    //       {t("vipDiscount")}{" "}
                    //       <span>
                    //         -{currency} {bookingData?.total_discount}
                    //       </span>
                    //     </li>
                    //   );
                    // }
                  })()}
                  {
                    bookingData?.total_discount >
                      0 &&(
                        <li>
                          {t("vipDiscount")}{" "}
                          <span>
                            -{currency} {bookingData?.total_discount}
                          </span>
                        </li>
                      )}

                  {/* <li>
                        {t('vat')} <span>{currency} 0</span>
                      </li> */}
                  {/* <li> 
                        {t('discount')} <span>-{currency}{" "}
                        {(() => {
                          let discount = 0;
                           if (appointment?.discount > 0) {
                            discount = appointment?.discount;
                          } 
                          if (appointment?.discount_price > 0) {
                            discount = appointment?.discount_price;
                          }
                          return discount;
                        })()}
                        
                        </span>
                      </li>  */}
                  <li>
                    {t("total")}{" "}
                    <span className="total">
                      {/* {isCashData.isCash && isCashData.price > 0? */}
                      <>
                        {currency}{" "}
                        {bookingData?.total_per_display_amount > 0 &&
                        bookingData?.FrequencyDetails.package_name === "One-off"
                          ? bookingData?.total_per_display_amount
                          : bookingData?.total_per_display_amount > 0 &&
                            bookingData?.FrequencyDetails.package_name ===
                              "Weekly"
                          ? bookingData?.total_amount +
                            (bookingData?.total_cod_charges > 0
                              ? bookingData?.total_cod_charges
                              : 0)
                          : bookingData?.total_amount +
                            (bookingData?.cod_charges > 0
                              ? bookingData?.cod_charges
                              : 0)}
                      </>
                      {/* :<> {currency}{" "}{appointment?.total_amount}</>} */}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="card-content justify-content-center">
                <Link to="/my-account/appointments" className="btn btn-primary">
                  {t("go_to_appointments")}
                </Link>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default SuccessBooking;
