import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, Link} from "react-router-dom";

const NowCheck = () => {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const currentUser =
    localStorage.getItem("currentUser") &&
    localStorage.getItem("currentUser") !== "null"
      ? JSON.parse(localStorage.getItem("currentUser"))
      : false;
  const handelBook = (step) => {
    navigate(`/frequency/checkout/details?step=${step}`, {
      state: {
        serviceId: "62385633517c11eaf7568824",
        isBookNow: true,
        isLogin: step === 2 ? false : true,
        ui_type: "Frequency"
      }
    });
  };
  return (
    <>
      <div className="nowCheckArea">
        <div className="container">
          <div className="nowcheckContent">
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-8">
                <h2>{t("now_check_that_off_to_do_list")}</h2>
                <p>{t("easy_On_the_go_booking_for_home")}</p>
              </div>
              <div className="col-lg-4 col-md-4">
                {/* <button className="btn btn-secondary btnLarge" onClick={(e) => document.getElementById('serviceShow').scrollIntoView()}>{t('lets_get_started')}</button> */}
                {currentUser ? (
                  <button
                    onClick={() => handelBook(1)}
                    className="btn btn-secondary btnLarge"
                  >
                    {t("lets_get_started")}
                  </button>
                ) : (
                  <button
                    onClick={() => handelBook(2)}
                    className="btn btn-secondary btnLarge"
                  >
                    {t("lets_get_started")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NowCheck;
