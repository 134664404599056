import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";
import Loader from "../../../components/Loader";
import {getTrackInvitesAction} from "../../../actions/invitationAction";
import {t} from "i18next";
import {getInviteHistory} from "../../../helpers/helpers";
import {useState} from "react";
import {useCountryCurrency} from "../../../helpers/useCountryCurrency";

const TrackInvites = () => {
  const getInvitationReducersState = useSelector(
    (state) => state.invitationReducer
  );
  const {loading, invites} = getInvitationReducersState;
  const [inviteCredit, setInviteCredit] = useState();
  const countryCurrency = useCountryCurrency();
  // console.log("countryCurrency", countryCurrency);
  const dispatch = useDispatch();
  const getInviteData = async () => {
    const getInvite = await getInviteHistory();
    // console.log("getInvite", getInvite);
    setInviteCredit(getInvite?.list);
  };

  let baseUrl = process.env.REACT_APP_LOCAL_API_URL;
  useEffect(() => {
    let apiUrl = baseUrl + "/customer/track-invites";
    getInviteData();

    dispatch(getTrackInvitesAction(apiUrl));
  }, []);

  return (
    <>
      <Header />
      {loading ? <Loader /> : ""}
      <div className="py-5">
        <div className="userContainer">
          <div className="appointments">
            <div className="heading">
              <h5>
                <Link to="/my-account/free-service">{t("free_services")}</Link>
              </h5>
              <h2>{t("track_your_invites")}</h2>
            </div>
            <div className="card">
              <div className="accountSettings trackInviteArea  ">
                <div className="trackInvites">
                  <h2>
                    {t("friend_referrals")}:{" "}
                    <span>
                      {invites && invites?.friend_referrals
                        ? invites.friend_referrals
                        : 0}
                    </span>
                  </h2>
                  <p>{t("total_friends_created_using_referral")}</p>
                </div>
                <div className="trackInvites">
                  <h2>
                    {t("successful_invites")}:{" "}
                    <span>
                      {invites && invites?.successful_invites
                        ? invites.successful_invites
                        : 0}
                    </span>
                  </h2>
                  <p>{t("total_friends_booked_service_via_your_referral")}</p>
                </div>
                <div>
                  {t("referral_credit_history")}
                  <div className="trackInvites">
                    {inviteCredit?.map((invite, index) => {
                      return (
                        <>
                          <div className="transtionList" key={index}>
                            <h3>
                              {invite?.details}{" "}
                              {invite?.credit > 0 && (
                                <span>
                                  + {invite?.credit}{" "}
                                  {countryCurrency?.length > 0 &&
                                    countryCurrency[0]?.currency}
                                </span>
                              )}
                              {invite?.debit > 0 && (
                                <span>
                                  - {invite?.debit}{" "}
                                  {countryCurrency?.length > 0 &&
                                    countryCurrency[0]?.currency}
                                </span>
                              )}
                            </h3>
                            <p
                              style={{
                                marginLeft: "33px",
                                marginTop: "20px",
                                marginBottom: "-15px"
                              }}
                            >
                              {invite?.dmy}
                            </p>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TrackInvites;
